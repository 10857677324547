<template>
  <div class="flex flex-col text-left">
    <div class="flex flex-col">
      <div class="w-1/2 pr-1 mb-2">
        <!-- <label for="">Mitarbeiter *</label> -->
        <v-select
          :options="roleprofiles"
          v-model="personal.roleprofile"
          :reduce="roleprofile => roleprofile.id"
          :clearable="false"
          placeholder="Rollenprofil auswählen"
          label="name"
          class="style-chooser mb-2 border rounded px-2 py-1 text-xs focus:outline-none bg-transparent w-full"
        />
      </div>

      <div class="w-full flex space-x-2">
        <div class="w-1/2 flex flex-col">
          <label for="">Pensum</label>
          <input
            type="text"
            v-model="personal.workload"
            class="mb-2 border rounded px-2 py-1 text-xs appearance-none focus:outline-none bg-transparent"
            :class="{ 'border-red-500': $v.personal.workload.$error }"
            placeholder="Pensum"
          />
        </div>

        <div class="w-1/2 flex flex-col">
          <label for="">Pensum Home Office</label>
          <input
            type="text"
            v-model="personal.workload_home_office"
            class="mb-2 border rounded px-2 py-1 text-xs appearance-none focus:outline-none bg-transparent"
            :class="{
              'border-red-500': $v.personal.workload_home_office.$error,
            }"
            placeholder="Pensum Home Office"
          />
        </div>
      </div>

      <div class="w-full flex space-x-2">
        <div class="w-1/2 flex flex-col">
          <label for="">Startdatum</label>
          <date-picker
            v-model="personal.start_date"
            :input-class="
              `border rounded px-4 py-2 w-full text-xs appearance-none focus:outline-none bg-transparent ${
                $v.personal.start_date.$error ? 'border-red-500' : ''
              }`
            "
            placeholder="Startdatum"
          />
        </div>

        <div class="w-1/2 flex flex-col">
          <label for="">Enddatum</label>
          <date-picker
            v-model="personal.leaving_date"
            :input-class="
              `border rounded px-4 py-2 w-full text-xs appearance-none focus:outline-none bg-transparent`
            "
            placeholder="Enddatum"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { getAll } from '@/services/roleprofile'
import { createOne, updateOne } from '@/services/employee'

const temppersonal = {
  personal: null,
  workload: '0',
  workload_home_office: '0',
  start_date: '',
  leaving_date: '',
  archived: false,
  roleprofile: null,
}

export default {
  name: 'new-roleprofile',
  props: ['payload', 'parent_id'],
  components: {},
  data() {
    return {
      personal: {...temppersonal},
      roleprofiles: [],
    }
  },
  validations() {
    return {
      personal: {
        roleprofile: {
          required,
        },
        workload: {
          required,
        },
        workload_home_office: {
          required,
        },
        start_date: {
          required,
        },
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {

      this.payload ? this.personal = { ...this.payload.employes } : 
      
      this.personal.personal = this.parent_id
      
      var no_pagination = true
      getAll(no_pagination).then(response => {
        this.roleprofiles = response.data.results
      })
    },
    save() {
      if (this.$v.$invalid) {
        this.$toast.warning('Bitte füllen Sie die erforderlichen Felder aus')
      } else {

        if (this.personal.id) {
          updateOne(this.personal, this.personal).then(() => {
            this.$emit('close-modal', { show: false })
            // this.$toast.success(response.data.message)
          })
        } else {
          createOne(this.personal)
            .then(() => {
              this.$emit('close-modal', { show: false })

              // // this.$toast.success(response.data.message)
            })
            .catch(err => {
              console.log('E ::: ', err.response)
            })
        }
      }
    },
  },
  watch: {},
}
</script>
